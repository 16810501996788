* {
  -webkit-tap-highlight-color: transparent;
}

.slider_container {
  display: flex;
  width: 724px;
  height: 66px;
  padding: 5px 30px;
  margin: auto 0;
  position: relative;
  background-color: #c0c0c0;
  box-sizing: border-box;
  z-index: 5;
  overflow: hidden;
  clip-path: polygon(
    0 0,
    calc(100% - 20px) 0,
    100% 20px,
    100% 100%,
    20px 100%,
    0 calc(100% - 20px)
  );

 & :global(.swiper) {
    width: 100%;
  }
}

.slider_container__noise {
  position: absolute;
  inset: 0;
  mix-blend-mode: color-burn;
  background-image: url("assets/noise.png");
  background-size: cover;
}

.swiper {
  height: 100%;
}

.navbtn {
  padding: 10px;
  z-index: 6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.navbtn::after,
.navbtn::before {
  content: "";
  height: 2px;
  width: 10px;
  background-color: #000;
}

.navbtn_prev {
  left: 5px;
}

.navbtn_next {
  right: 5px;
}

.navbtn_prev.navbtn::after {
  transform: rotate(45deg);
}

.navbtn_prev.navbtn::before {
  transform: rotate(-45deg);
  margin-bottom: 4px;
}

.navbtn_next.navbtn::after {
  transform: rotate(-45deg);
}

.navbtn_next.navbtn::before {
  transform: rotate(45deg);
  margin-bottom: 4px;
}

.navbtn.swiper-button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.swiper_slide + .swiper_slide:not(:global(.swiper-slide-active)) {
  border-left: 1px solid #000;
}

.swiper-slide.swiper-slide-active {
  border: none;
}

@media (max-width: 1919px) {
  .slider_container {
    width: 590px;
  }
}

@media (max-width: 1599px) {
  .navbtn_prev {
    left: 5px;
  }

  .navbtn_next {
    right: 5px;
  }
}

@media (max-width: 1535px) {
  .slider_container {
    width: 467px;
  }

  .navbtn_prev {
    left: 0;
  }

  .navbtn_next {
    right: 0;
  }
}

@media (max-width: 1365px) {
  .slider_container {
    width: 342px;
  }
}

@media (max-width: 1279px) {
  .slider_container {
    width: 334px;
  }
}

@media (max-width: 1079px) {
  .slider_container {
    width: 299px;
    height: 62px;
    padding: 5px 25px;
  }
}

@media (max-width: 827px) {
  .slider_container {
    width: 137px;
    height: 68px;
    padding: 5px 15px;
  }

  .navbtn_prev {
    left: -5px;
  }
  
  .navbtn_next {
    right: -5px;
  }
  
  .swiper_slide + .swiper_slide {
    border-left: none !important;
  }
}

@media (max-width: 719px) {
  .slider_container {
    width: 159px;
    height: 74px;
    padding: 5px 25px;

    &_size_sxs {
      width: 230px;
      height: 89px;
    }
  }

  .slider_container_size_sxs { 
    .navbtn_prev {
      left: 10px;
    }
    
    .navbtn_next {
      right: 10px;
    }
  }
}

@media (max-width: 424px) { 
  .slider_container {
    width: 159px;
    height: 74px;
    padding: 5px 20px;
  }

  .slider_container_size_sxs {
    .navbtn_prev {
      left: -5px;
    }
    
    .navbtn_next {
      right: -5px;
    }
  }
}


