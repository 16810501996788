* {
  -webkit-tap-highlight-color: transparent;
}

.slider_container {
  display: flex;
  min-width: 0;
  max-width: 590px;
  height: 66.87px;
  padding: 5px 30px;
  margin: auto 0;
  position: relative;
  background-color: #c0c0c0;
  box-sizing: border-box;
  z-index: 5;
  overflow: hidden;
  clip-path: polygon(
    0 0,
    calc(100% - 20px) 0,
    100% 20px,
    100% 100%,
    20px 100%,
    0 calc(100% - 20px)
  );
}

.slider_container__noise {
  position: absolute;
  inset: 0;
  mix-blend-mode: color-burn;
  background-image: url("assets/noise.png");
  background-size: cover;
}

.swiper {
  height: 100%;
}

.navbtn {
  padding: 10px;
  z-index: 6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.navbtn::after,
.navbtn::before {
  content: "";
  height: 2px;
  width: 10px;
  background-color: #000;
}

.navbtn_prev {
  left: 5px;
}

.navbtn_next {
  right: 5px;
}

.navbtn_prev.navbtn::after {
  transform: rotate(45deg);
}

.navbtn_prev.navbtn::before {
  transform: rotate(-45deg);
  margin-bottom: 4px;
}

.navbtn_next.navbtn::after {
  transform: rotate(-45deg);
}

.navbtn_next.navbtn::before {
  transform: rotate(45deg);
  margin-bottom: 4px;
}

.navbtn.swiper-button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.swiper_slide + .swiper_slide:not(:global(.swiper-slide-active)) {
  border-left: 1px solid #000;
}

.swiper-slide.swiper-slide-active {
  border: none;
}

@media (max-width: 1600px) {
  .navbtn_prev {
    left: 5px;
  }

  .navbtn_next {
    right: 5px;
  }
}

@media (max-width: 1300px) {
  .slider_container {
    width: initial;
    max-width: 490px;
  }

  .navbtn_prev {
    left: 0;
  }

  .navbtn_next {
    right: 0;
  }
}

@media (max-width: 1150px) {
  .slider_container {
    width: initial;
    max-width: 488.52px;
    height: 70px;
  }
}

@media (max-width: 104px) {
  .slider_container {
    width: initial;
    max-width: 488.52px;
    height: 70px;
  }
}

@media (max-width: 969px) {
  .slider_container {
    width: initial;
    height: 70px;
  }
}

@media (max-width: 763px) {
  .slider_container {
    padding: 5px 17px;
  }
}

@media (max-width: 450px) {
  .swiper_slide + .swiper_slide {
    border-left: none;
  }
  .navbtn_prev {
    left: -5px;
  }

  .navbtn_next {
    right: 5px;
  }
}
@media (max-width: 480px) {
  .slider_container {
    width: initial;
    max-width: 204px;
    height: 68px;
    padding: 5px 15px;
  }
}
@media (max-width: 320px) {
  .slider_container {
    width: initial;
    max-width: 163px;
    height: 76.2px;
  }
}

@media (max-width: 300px) {
  .slider_container {
    width: initial;
    max-width: 149px;
    height: 76.2px;
  }

  .navbtn_next {
    right: -5px;
  }
}
