.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 6px;
  height: 100%;
  padding: 0 11px;
  box-sizing: border-box;
  background-color: transparent;
}

.card__grid_top,
.card__grid_bottom {
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  justify-items: center;
  align-items: center;
}

.card__grid_bottom {
  justify-items: center;
}

.card__grid_bottom svg {
  width: 9px;
  height: 9px;
}

.card__grid_top {
  gap: 8px;
}

.card__grid_bottom {
  gap: 3px;
}

.card__grid_top .card__text {
  display: flex;
  height: 33px;
  align-items: center;
  justify-content: center;
}

.card__text.card__text_name {
  font-size: 9px;
  word-break: break-all;
}

.card__logo,
.card__text.card__text_name {
  width: 35px;
  height: 35px;
}

.card__logo {
  width: 35px;
  height: 35px;
  display: block;
  object-fit: contain;
  object-position: center;
}

.card__tab_link {
  font-family: "Gilroy";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 15px;
  width: 46px;
  max-width: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  line-height: 1;
  color: #0d0d0d;
  background-color: #ffffff;
  transition-duration: 200ms;
}

.card__result {
  margin: 0 2px 0 0;
  padding: 2px 0 0 0;
}

.card__tab_link:hover {
  background-color: #f8e800;
}

.card__tab_link:hover .card__result {
  color: #0d0d0d;
}

.card__text {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 9px;
  line-height: 9px;
  font-style: normal;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

@media (max-width: 1079px) {
  .card__logo {
    height: 32px;
    width: 32px;
  }

  .card__tab_link {
    width: 43.75px;
    height: 14.27px;
    font-size: 9.37px;
    line-height: 9.37px;
  }

  .card__result {
    margin: 0 3px 0 0;
  }
}

@media (max-width: 827px) {
  .card {
    padding: 0;
    justify-content: initial;
  }

  .card__logo,
  .card__text.card__text_name {
    width: 34px;
    height: 25px;
  }
}

@media (max-width: 719px) {
  .card {
    justify-content: center;
  }

  .card__logo,
  .card__text.card__text_name {
    width: 36px;
    height: 35px;
  }

  .card__tab_link {
    min-width: 47px;
    height: 15px;
    font-weight: 700;
    font-size: 10.6px;
    line-height: 10.6px;
  }

  .card__result {
    height: 100%;
    padding: 2.4px 0 0 0px;

    &:first-of-type {
      padding: 2.4px 0 0 5px;
    }
  }
}
