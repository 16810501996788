.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
  max-width: 350px;
  height: 100%;
  transition-duration: 200ms;
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 32px;
  line-height: 22px;
  white-space: nowrap;
  flex-shrink: 0;
  text-transform: uppercase;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 90% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 90% 100%, 0% 100%);
}

.btn:hover {
  background-color: #fff829;
}

.btn_primary {
  background-color: #f8e800;
  color: #0d0d0d;
}

.btn_secondary {
  background-color: #ff0025;
  color: #ffffff;
}

.btn_link {
  width: 100%;
}
