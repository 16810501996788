.container {
  display: flex;
  align-items: center;
  height: 160px;
  padding: 0 28px 0 45px;
  background-image: url("assets/melbet_bg/bg-2543x160.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  position: relative;
  background-position: 0 -1px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 67px;
  overflow: hidden;
  position: relative;
}

.grid__noise {
  background-image: url("assets/noise.png");
  background-size: contain;
  mix-blend-mode: color-burn;
  position: absolute;
  inset: 0;
}

.grid__col_description {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grid__col_preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 11px;
}

.preview-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.grid__col_btn {
  align-self: center;
  width: 349;
  height: 72px;
}

.logo {
  display: block;
  width: 252.5px;
  height: 92.5px;
  flex-shrink: 0;
}

.title,
.subtitle {
  font-family: "HalvarBreit";
  font-style: normal;
  font-style: normal;
  font-weight: 900;
  font-size: 47px;
  line-height: 47px;
  margin: 0;
  white-space: nowrap;
  text-transform: uppercase;
}

.bonus {
  width: 293.84px;
  height: 18.92px;
}

.grid__col_btn {
  max-width: 349px;
}
.btn_mobile {
  display: none;
}

.disclaimer_mobile {
  display: none;
}

.grid__col_disclaimer {
  display: none;
}

.mobile_disclaimer {
  position: absolute;
  bottom: 15px;
  left: 45px;
  width: 208px;
  height: 10px;
  background-image: url("assets/default_ds/ds-1149.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1920px) {
  .grid {
    gap: 20px;
  }

  .grid__col_description {
    justify-content: center;
  }

  .grid__col.grid__col_preview {
    margin: 0 0 0 5px;
  }

  .grid__col.grid__col_slider {
    margin: 0 0 0 15px;
  }

  .grid__col.grid__col_btn {
    display: none;
  }

  .container {
    background-image: url("assets/melbet_bg/bg-1920x160.png");
  }

  .mobile_disclaimer {
    filter: invert(1);
    width: 209px;
    height: 9px;
    background-image: url("assets/icons/disclaimer.svg");
    background-size: cover;
  }

  .btn_mobile {
    display: flex;
    width: 208.65px;
    height: 43.91px;
  }
  .logo {
    width: 208px;
    height: 36px;
  }
  .title {
    font-family: "HalvarBreit";
    font-style: normal;
    font-weight: 900;
    font-size: 48.7558px;
    line-height: 46px;
    margin: 0;
    text-transform: uppercase;
    white-space: pre-line;
  }

  .subtitle {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    font-size: 34.1291px;
    line-height: 36px;
    margin: 0;
    white-space: nowrap;
    text-transform: uppercase;
  }
}

@media (max-width: 1536px) {
  .grid__col.grid__col_preview {
    margin: 0 0 0 5px;
  }

  .grid__col.grid__col_slider {
    margin: 0 0 0 15px;
  }

  .grid__col.grid__col_btn {
    margin: 0;
  }

  .container {
    background-image: url("assets/melbet_bg/bg-1536х160.png");
  }
}

@media (max-width: 1366px) {
  .container {
    background-image: url("assets/melbet_bg/bg-1366х160.png");
    background-position: 0 -1px;
  }
}

@media (max-width: 1280px) {
  .container {
    background-image: url("assets/melbet_bg/bg-1280х160.png");
  }

  .grid__col.grid__col_preview {
    display: none;
  }
}

@media (max-width: 1080px) {
  .container {
    padding: 0 20px 0 20px;
    background-image: url("assets/melbet_bg/bg-1080х160.png");
    background-position: 0 -1px;
  }

  .bonus_mobile {
    width: 158px;
    height: 9px;
  }

  .grid__col_btn .btn {
    display: none;
  }

  .btn_mobile {
    display: flex;
    width: 178px;
    height: 37px;
  }

  .grid__col_description {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  .logo {
    width: 178px;
    height: 31px;
  }

  .mobile_disclaimer {
    left: 20px;
    width: 173px;
    height: 7px;
  }
}

@media (max-width: 829px) {
  .container {
    background-image: url("assets/melbet_bg/bg-828х160.png");
    background-position: 0 -1px;
  }
}

@media (max-width: 750px) {
  .container {
    background-image: url("assets/melbet_bg/bg-750х160.png");
    background-position: 0 -1px;
  }
}

@media (max-width: 720px) {
  .container {
    background-image: url("assets/melbet_bg/bg-720х160.png");
    background-position: 0 -1px;
  }
}

@media (max-width: 480px) {
  .container {
    background-image: url("assets/melbet_bg/bg-331x160.png");
    background-position: 0 -1px;
    padding: 0 10px 0 10px;
  }
  .grid {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .grid__col_description {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  }

  .logo {
    width: 128px;
    height: 22px;
  }

  .btn_mobile {
    max-width: 160px;
    height: 32px;
  }

  .bonus {
    max-width: 134px;
    height: 8px;
  }

  .mobile_disclaimer {
    display: none;
  }

  .grid__col_disclaimer {
    display: block;
  }
}
