.container {
  position: relative;
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 80px;
  width: 100%;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 17px 0 15px;
  background-image: url("assets/default_bg/bg-1919x80.png");
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 20px;
  overflow: hidden;
  position: relative;
}

.grid__noise {
  background-image: url("assets/noise.png");
  background-size: contain;
  mix-blend-mode: color-burn;
  position: absolute;
  inset: 0;
  height: inherit;
}

.grid__col_preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 11px;
  white-space: nowrap;
}

.grid__col_slider {
  margin: auto 0;
}

.preview-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.grid__col_btn {
  align-self: center;
}

.logo {
  display: block;
  width: 252.5px;
  height: 92.5px;
  flex-shrink: 0;
}

.title,
.subtitle {
  font-family: "HalvarBreit";
  font-style: normal;
  font-weight: 900;
  font-size: 33px;
  line-height: 33px;
  margin: 0;
  text-transform: uppercase;
}

.btn_mobile {
  display: none;
}

.btn_link {
  width: 207px;
  height: 42.7px;
}

.grid__col_btn .btn {
  width: 207px;
  height: 42.7px;
}

.disclaimer_mobile {
  display: none;
}

.bonus {
  max-width: 174.28px;
  height: 11.22px;
}

.grid__col_btn {
  max-width: 207px;
  height: 42.7px;
}
.grid__col_disclaimer {
  display: none;
}

@media (max-width: 1150px) {
  .grid {
    align-items: center;
  }

  .container {
    height: 90px;
    padding: 0 11px 0 15px;
    background-image: url("assets/default_bg/bg-1149-90.png");
    background-position: initial;
  }

  .logo {
    width: 161px;
    height: 27px;
    margin-bottom: 15px;
  }

  .title {
    min-width: 200px;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 28px;
    white-space: pre-line;
    text-transform: uppercase;
  }

  .subtitle {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 22px;
    white-space: nowrap;
    text-transform: none;
  }

  .grid__col_btn {
    width: 199px;
    height: 42px;
  }

  .bonus {
    width: 167.55px;
    height: 10.91px;
  }

  .mobile_disclaimer {
    position: absolute;
    bottom: 10px;
    left: 11px;
    width: 130px;
    height: 5px;
    background-image: url("assets/default_ds/ds-1149.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1050px) {
  .container {
    height: 90px;
    padding: 0 11px;
    background-image: url("assets/default_bg/bg-1049x90.png");
  }

  .grid {
    display: flex;
  }

  .btn.btn_mobile {
    display: none;
  }

  .grid__col_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: initial;
    padding-top: 0;
  }

  .grid__col_description > .btn_link {
    width: 114px;
  }

  .grid__col.grid__col_slider {
    flex-grow: 1;
  }
  .title {
    white-space: pre-line;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
  }

  .subtitle {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 22px;
  }
  .grid__col_btn {
    width: 163px;
    height: 34px;
  }

  .bonus {
    width: 137.24px;
    height: 8.93px;
  }
  .logo {
    width: 121px;
    height: 21px;
    margin: 0;
  }

  .mobile_disclaimer {
    bottom: 5px;
    left: 11px;
    width: 76px;
    max-width: 393px;
    height: 11px;
    background-image: url("assets/default_ds/ds-969.svg");
  }
}

@media (max-width: 1000px) {
  .container {
    height: 90px;
    padding: 0 11px;
    background-image: url("assets/default_bg/bg-1049x90.png");
  }

  .grid {
    display: flex;
  }

  .btn_mobile {
    display: none;
  }

  .title {
    white-space: pre-line;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    min-width: 160px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  }

  .grid__col_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: initial;
    padding-top: 0;
  }

  .grid__col_description > .btn_link {
    width: 114px;
  }

  .grid__col.grid__col_slider {
    flex-grow: 1;
  }

  .grid__col_btn {
    width: 142px;
    height: 29px;
  }

  .bonus {
    width: 119px;
    height: 7px;
  }

  .grid__col_preview {
    padding: 0;
  }
}

@media (max-width: 970px) {
  .container {
    background-image: url("assets/default_bg/bg-969x90.png");
  }

  .title {
    min-width: none;
  }
}

@media (max-width: 690px) {
  .grid {
    display: flex;
    gap: 10px;
  }

  .bonus {
    width: 90px;
    height: 7px;
  }

  .grid__col_btn button {
    width: 105px;
    height: 29px;
  }
}

@media (max-width: 900px) {
  .container {
    background-image: url("assets/default_bg/bg-424x100.png");
    height: 100px;
  }
  .grid {
    display: flex;
    gap: 10px;
  }
  .grid__col_btn {
    display: none;
  }

  .logo {
    width: 149px;
    height: 25.9px;
  }
  .btn_mobile {
    display: flex;
    align-items: center;
    width: 149px;
    height: 29.9px;
  }

  .bonus_mobile {
    width: 125.45px;
    height: 7.86px;
  }
  .grid__col_description {
    gap: 9px;
    display: flex;
  }

  .title,
  .subtitle {
    display: none;
  }

  .mobile_disclaimer {
    bottom: 2px;
    left: 11px;
    width: 100%;
    max-width: 393px;
    height: 10px;
    background-image: url("assets/default_ds/ds-424.svg");
  }
}

@media (max-width: 400px) {
  .mobile_disclaimer {
    max-width: 95%;
  }
}

@media (max-width: 320px) {
  .container {
    background-image: url("assets/default_bg/bg-319x100.png");
    height: 100px;
  }
  .grid {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .grid__col_btn {
    display: none;
  }

  .title,
  .subtitle {
    display: none;
  }
  .logo {
    width: 118px;
    height: 20px;
  }
  .btn_mobile {
    display: flex;
    align-items: center;
    width: 118px;
    height: 23px;
  }

  .bonus_mobile {
    width: 100.19px;
    height: 6.27px;
  }
  .grid__col_description {
    gap: 9px;
    display: flex;
  }

  .mobile_disclaimer {
    bottom: 5px;
    width: 59px;
    height: 10px;
    background-image: url("assets/default_ds/ds-319.svg");
  }
}
