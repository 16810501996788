.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  height: 100%;
  padding: 0 11px;
  box-sizing: border-box;
  background-color: transparent;
}

.card__grid_top,
.card__grid_bottom {
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  justify-items: center;
  align-items: center;
  gap: 5px;
}

.card__grid_bottom {
  justify-items: center;
}

.card__grid_bottom svg {
  width: 9px;
  height: 9px;
}

.card__grid_top .card__text {
  display: flex;
  height: 33px;
  align-items: center;
  justify-content: center;
}

.card__text.card__text_name {
  font-size: 9px;
  word-break: break-all;
}

.card__logo,
.card__text.card__text_name {
  width: 45px;
  height: 33px;
}

.card__logo {
  width: 44px;
  height: 35px;
  display: block;
  object-fit: contain;
  object-position: center;
}

.card__tab_link {
  font-family: "Gilroy";
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 2px;
  height: 16px;
  width: 40px;
  max-width: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  line-height: 1;
  color: #0d0d0d;
  background-color: #ffffff;
  transition-duration: 200ms;
}

.card__tab-_ink span {
  padding: 2px 0 0 0;
}

.card__tab_link:hover {
  background-color: #f8e800;
}

.card__tab_link:hover .card__result {
  color: #0d0d0d;
}

.card__text {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 9px;
  line-height: 9px;
  font-style: normal;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

@media (max-width: 1300px) {
  .card {
    padding: 0 11px;
  }
}

@media (max-width: 1150px) {
  .card__tab_link {
    width: 47px;
    height: 16px;
  }
}

@media (max-width: 999px) {
  .card__tab_link {
    width: 44px;
    height: 14px;
  }
}

@media (max-width: 506px) {
  .card__grid_top,
  .card__grid_bottom {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    justify-items: center;
    align-items: center;
    gap: 4px;
  }
}
