$cubic-bizier: cubic-bezier(0.4, 0, 0.2, 1);
$animation-time: 0.3s;
$animation-delay-main: 4.7s;
$animation-delay-disclaimer: 2.7s;

.container {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 116px 0 17px;
  background-image: url("assets/cyber_bg/bg-1920x80-left.svg"),
    url("assets/cyber_bg/bg-1920x80-rigth.svg"),
    url("assets/cyber_bg/bg-1920x80.png");
  background-repeat: no-repeat;
  background-size: auto, auto, cover;
  background-position: left bottom, right bottom, left bottom;
  color: #ffffff;
  position: relative;

  @media (hover: hover) {
    &:hover .animationFadeInCurrent {
      animation-play-state: paused;
    }
  }
}

.grid {
  display: grid;
  width: 100%;
  min-height: 66px;
  grid-template-columns: auto repeat(2, max-content) auto;
  gap: 40px;
  overflow: hidden;
  position: relative;

  &__col_description {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__col_preview {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__col_btn {
    align-self: center;
    justify-self: end;
    width: 266px;
    height: 54px;
  }

  &__noise {
    background-image: url("assets/noise.png");
    background-size: contain;
    mix-blend-mode: color-burn;
    position: absolute;
    inset: 0;
    height: inherit;
  }
}

.mobile__disclaimer {
  width: 169px;
  height: 9px;
  background-image: url("assets/icons/disclaimer.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 5px;
  left: 17px;
  transition: opacity $animation-time ease;

  &_hidden {
    opacity: 0;
  }
}

.preview-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.logo {
  display: block;
  width: 251px;
  flex-shrink: 0;
}

.title,
.subtitle {
  font-family: "HalvarBreit";
  font-style: normal;
  font-style: normal;
  font-weight: 900;
  font-size: 33px;
  line-height: 33px;
  margin: 0;
  white-space: nowrap;
  text-transform: uppercase;
}

.bonus {
  width: 223px;
  height: 14px;
}

.btn__mobile {
  display: none;
}

.grid__col_disclaimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.disclaimer {
  max-width: 1377px;
  height: 60px;
  font-family: "Gilroy";
  font-size: 21.16px;
  line-height: 19.79px;
  font-weight: 400;

  &__link {
    color: #ffffff;
    text-decoration: underline;
  }

  &__separator {
    display: inline-block;
  }

  &__break {
    display: none;
  }
}

@media (max-width: 1919px) {
  .grid {
    grid-template-columns: repeat(4, auto);
    gap: 20px;
    min-height: initial;

    &__col_description {
      justify-content: center;
    }

    &__col.grid__col_preview {
      margin: 0 0 0 15px;
    }
  }

  .container {
    padding: 0 15px 0 23px;
    background-image: url("assets/cyber_bg/bg-1920x80-left.svg"),
      url("assets/cyber_bg/bg-1600x80-rigth.svg"),
      url("assets/cyber_bg/bg-1600x80.png");
  }

  .mobile__disclaimer {
    left: 23px;
  }

  .logo {
    width: 197px;
    height: 34px;
  }

  .disclaimer {
    display: inline-flex;
    align-items: center;
    max-width: 1249px;
    height: 64px;
    font-size: 16.74px;
    line-height: 15.65px;

    &__separator {
      display: none;
    }

    &__break {
      display: block;
    }
  }
}

@media (max-width: 1599px) {
  .container {
    gap: 13px;
    background-image: url("assets/cyber_bg/bg-1920x80-left.svg"),
      url("assets/cyber_bg/bg-1600x80-rigth.svg"),
      url("assets/cyber_bg/bg-1536x80.png");
  }

  .grid {
    &__col.grid__col_preview {
      margin: 0;
      padding: 0;
    }

    &__col.grid__col_slider {
      margin: 0;
    }

    &__col_btn {
      width: 246px;
      height: 50px;
    }
  }

  .title,
  .subtitle {
    font-size: 32px;
    line-height: 32px;
  }

  .bonus {
    width: 207px;
    height: 13px;
  }
}

@media (max-width: 1535px) {
  .title,
  .subtitle {
    font-size: 30px;
    line-height: 30px;
  }

  .container {
    padding: 0 10px 0 23px;
    background-image: url("assets/cyber_bg/bg-1920x80-left.svg"),
      url("assets/cyber_bg/bg-1600x80-rigth.svg"),
      url("assets/cyber_bg/bg-1366x80.png");
  }

  .grid {
    gap: 0px;

    &__col.grid__col_preview {
      display: flex;
      justify-content: center;
      padding: 0 10px 0 15px;
    }

    &__col_btn {
      width: 232px;
      height: 47px;
    }
  }

  .disclaimer {
    max-width: 1000px;
    height: 60px;
    font-size: 16px;
    line-height: 14.96px;
  }
}

@media (max-width: 1365px) {
  .container {
    background-image: url("assets/cyber_bg/bg-1920x80-left.svg"),
      url("assets/cyber_bg/bg-1600x80-rigth.svg"),
      url("assets/cyber_bg/bg-1280x80.png");
  }

  .title,
  .subtitle {
    font-size: 31px;
    line-height: 31px;
  }

  .disclaimer {
    max-width: 900px;
    height: 66px;
    font-size: 14.54px;
    line-height: 14.53px;
  }
}

@media (max-width: 1279px) {
  .container {
    padding: 0 17.5px 0 17px;
    background-image: url("assets/cyber_bg/bg-1080x80-left.svg"),
      url("assets/cyber_bg/bg-1080x80-rigth.svg"),
      url("assets/cyber_bg/bg-1080x80.png");
  }

  .grid {
    &__col_description {
      display: flex;
      flex-direction: column;
    }

    &__col.grid__col_preview {
      justify-content: center;
      padding: 0;
    }

    &__col_btn {
      width: 202px;
      height: 41px;
    }
  }

  .logo {
    width: 140px;
    height: 24px;
  }

  .title,
  .subtitle {
    font-size: 23px;
    line-height: 23px;
  }

  .bonus {
    width: 170px;
    height: 11px;
  }

  .mobile__disclaimer {
    width: 140px;
    height: 8px;
    left: 17px;
  }

  .disclaimer {
    max-width: 779px;
    height: 67px;
    font-size: 13.413px;
    line-height: 1;
  }

  .container.container_size_sxs {
    height: 100px;
    background-image: url("assets/cyber_bg/bg-1080x100-left.svg"),
      url("assets/cyber_bg/bg-1080x100-rigth.svg"),
      url("assets/cyber_bg/bg-1080x100.png");
  }
}

@media (max-width: 1079px) {
  .container {
    padding: 0 20px 0 17px;
    background-image: url("assets/cyber_bg/bg-1080x80-left.svg"),
      url("assets/cyber_bg/bg-1080x80-rigth.svg"),
      url("assets/cyber_bg/bg-828x80.png");
  }

  .title {
    white-space: pre-line;
    width: 160px;
    font-size: 24px;
    line-height: 22px;
  }

  .grid {
    &__col.grid__col_preview {
      justify-content: space-between;
      justify-self: flex-end;
    }
 
    &__col.grid__col_slider {
      justify-self: flex-end;
    }
 
    &__col_btn {
      width: 160px;
      height: 33px;
    }
  }

  .subtitle {
    font-family: "Gilroy";
    font-size: 16px;
    line-height: 17px;
    text-transform: none;
  }

  .logo {
    width: 136px;
    height: 23px;
  }

  .bonus {
    width: 134px;
    height: 8px;
  }

  .mobile__disclaimer {
    width: 131px;
    height: 7px;
  }

  .disclaimer {
    max-width: 565px;
    height: 65px;
    font-size: 12.219px;
    line-height: 12.83px;
  }

  .container_size_sxs {
    background-image: url("assets/cyber_bg/bg-1080x100-left.svg"),
      url("assets/cyber_bg/bg-1080x100-rigth.svg"),
      url("assets/cyber_bg/bg-828x100.png");
    padding: 0 10px 0 20px;

     & .mobile__disclaimer {
        bottom: 7px;
        left: 20px;
      }
  }
}

@media (max-width: 827px) {
  .container {
    background-image: url("assets/cyber_bg/bg-1080x80-left.svg"),
      url("assets/cyber_bg/bg-1080x80-rigth.svg"),
      url("assets/cyber_bg/bg-720x80.png");
  }

  .title {
    width: 190px;
    font-size: 28px;
    line-height: 26px;
  }

  .grid {
    &__col.grid__col_slider {
      display: flex;
    }

    &__col.grid__col_preview, &__col.grid__col_slider {
      justify-self: initial;
    }

    &__col_btn {
      width: 172px;
      height: 35px;
    }
  }

  .subtitle {
    font-size: 19px;
    line-height: 20px;
  }

  .logo {
    width: 147px;
    height: 25px;
  }

  .bonus {
    width: 144px;
    height: 9px;
  }

  .mobile__disclaimer {
    width: 143px;
    height: 8px;
    bottom: 3px;
  }

  .disclaimer {
    max-width: 430px;
    height: 66px;
    font-size: 11.467px;
    line-height: 11px;
  }

  .container_size_sxs {
    background-image: url("assets/cyber_bg/bg-1080x100-left.svg"),
      url("assets/cyber_bg/bg-1080x100-rigth.svg"),
      url("assets/cyber_bg/bg-720x100.png");
  }
}

@media (max-width: 719px) {
  .container {
    padding: 0px 10px 0 16px;
    background-image: url("assets/cyber_bg/bg-425x80-left.svg"),
      url("assets/cyber_bg/bg-425x80-rigth.svg"),
      url("assets/cyber_bg/bg-332x80.png");
    background-position: left bottom, right top, left top;
    background-size: auto, contain, cover;

    &_with_disclaimer {
      align-items: initial;

      & .btn__mobile,
      :global(.btn_link) {
        display: none;
      }
    }
  }

  .grid {
    display: flex;
    gap: 15px;
  }

  .logo {
    width: 117px;
    height: 20px;
  }

  .bonus_mobile {
    width: 99px;
    height: 7px;
  }

  .mobile__disclaimer {
    width: 105px;
    height: 6px;
    left: 16px;
    animation: fadeOut $animation-time ease;
  }

  .disclaimer {
    max-width: 430px;
    height: auto;
    font-size: 11px;
    line-height: 98%; 
  }

  .container.container_size_sxs {
    background-image: url("assets/cyber_bg/bg-425x80-left.svg"),
      url("assets/cyber_bg/bg-425x80-rigth.svg"),
      url("assets/cyber_bg/bg-425x100.png");
      padding: 0px 10px 0 16px;

      & .mobile__disclaimer {
         bottom: 5px;
         left: 17px;
       }
      & .logo{
      margin-top:0;
    }
  }
}

@media (max-width: 650px) {


  .logo {
    width: 117px;
    height: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;

    &__col_description {
      gap: 3px;
      min-width: 140px;
    }

    &__col_btn {
      display: none;
    }

    &__col.grid__col_preview {
      display: none;
    }
  }

  .disclaimer {
    font-size: 6px;
    white-space: pre-line;
  }

  .btn__mobile {
    display: flex;
    margin-top:2px;
    max-width: 118px;
    height: 21px;

    &_hidden {
      display: none;
    }
  }

  .container.container_size_sxs {
    background-image: url("assets/cyber_bg/bg-425x80-left.svg"),
      url("assets/cyber_bg/bg-425x80-rigth.svg"),
      url("assets/cyber_bg/bg-332x100.png");

    & .grid {
      gap: 6px;
      padding: 0;

      &__col_slider {
        width: auto;
        justify-self: flex-end;
      }

      &__col_description {
        & .btn__mobile {
          padding: 9.48px 9.59px 9.11px 10.15px;
        }
      }
    }
  }
}

.animationContainer {
  animation: fadeIn 0.21s ease forwards,
    slideIn $animation-time $cubic-bizier forwards,
    fadeIn 90ms ease $animation-delay-main reverse forwards,
    slideIn $animation-time $cubic-bizier $animation-delay-main reverse forwards;

  &_legal {
    animation: fadeIn 0.21s ease forwards,
      slideIn $animation-time $cubic-bizier forwards,
      fadeIn 90ms ease $animation-delay-disclaimer reverse forwards,
      slideIn $animation-time $cubic-bizier $animation-delay-disclaimer reverse
        forwards;
  }

  &_paused {
    animation-play-state: paused;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInMobile {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    display: none;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}
